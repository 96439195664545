import React from "react";

const NotFound = ({ message, title }) => {
    return (<div className="not_found">
        <h3>{title || 'No data available.'}</h3>
        <p>{message || 'Oops! looks like we ran out of data. we are sorry for the inconvenience'}</p>
        <img src="https://rojal.s3.ap-south-1.amazonaws.com/rojal/defaults/not_found.png" alt="" />
    </div>);
};

export { NotFound };